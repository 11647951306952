import { Typography, Box } from '@mui/material';

const Privacy = () => {
  return (
    <Box p={2} maxWidth={960} margin="0 auto">
      <Typography component="h1" variant="h4" textAlign="center" my={2}>
        นโยบายข้อมูลส่วนบุคคล
      </Typography>
      <Typography component="p">
        www.codesass.com ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้งาน
        เราจึงกำหนดนโยบายเกี่ยวกับข้อมูลส่วนบุคคลเพื่อยกระดับการรักษาความปลอดภัยของข้อมูลส่วนบุคคลต่อผู้เข้าเยี่ยมชม
        และใช้บริการเว็บไซต์ให้ดียิ่งขึ้น
        เพื่อประโยชน์สูงสุดของท่านโปรดอ่านข้อมูลต่อไปนี้เพื่อทราบถึงแนวทางปฏิบัติเกี่ยวกับนโยบายส่วนบุคคลของเรา
      </Typography>
      <Typography variant="h5" my={2}>
        ความหมายของข้อมูลส่วนบุคคล
      </Typography>
      <Typography component="p">
        "ข้อมูลส่วนบุคคล" หมายถึง ข้อมูลเกี่ยวกับบุคคล (ผู้ใช้งานเว็บไซต์ของเรา)
        ที่สามารถระบุตัวบุคคลไม่ว่าทางตรงหรือทางอ้อมได้
      </Typography>
      <Typography variant="h5" my={2}>
        การเก็บรวบรวมข้อมูลส่วนบุคคล
      </Typography>
      <Typography component="p">
        เราจะทำการจัดเก็บข้อมูลส่วนบุคคลของคุณผ่านผู้ให้บริการเซิฟเวอร์ภายนอก
        โดยข้อมูลของคุณจะถูกจัดเก็บอย่างเป็นความลับ
        และไม่อาจเข้าถึงได้จากบุคคลภายนอกเว้นเสียแต่ได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลเสียก่อน
        หรือให้เป็นไปตามข้อบังคับทางกฏหมายแห่งราชอาณาจักรไทยโดยเราจะเปิดเผยข้อมูลส่วนบุคคลของคุณเท่าที่จำเป็นให้แก่หน่วยงานราชการ
        เช่น ศาล หรือ หน่วยงานราชการ เป็นต้น ข้อมูลส่วนบุคคลที่เราจัดเก็บ ได้แก่
        <ul>
          <li>
            ข้อมูลที่ผู้ใช้บริการได้ให้ไว้กับเราโดยสมัครใจ เช่น
            ข้อมูลการลงทะเบียนสมัครสมาชิก อันได้แก่ ชื่อ-นามสกุล และ อีเมล์
            เป็นต้น หรือการแสดงความคิดเห็นอันปรากฏต่อสาธารณะในเว็บไซต์ของเรา
            ข้อมูลส่วนนี้อาจถูกเปลี่ยนแปลงและแก้ไขได้โดยตรงจากผู้ดำเนินการคือ
            ผู้ใช้บริการที่ได้ให้ข้อมูลไว้ รวมถึงการกดให้คะแนน และอื่น ๆ
            หรือเจ้าหน้าที่ของทางเว็บไซต์โดยกระทำการผ่านความเห็นชอบของเจ้าของข้อมูล
          </li>
          <li>
            ข้อมูลที่เกี่ยวกับการใช้บริการ เช่น ข้อมูลการทำธุรกรรมและการเงิน
            รวมถึงประวัติการสั่งซื้อสินค้าหรือการเข้าชมหน้าเพจของเว็บไซต์
            ข้อมูลเหล่านี้เป็นส่วนสำคัญต่อการวิเคราะห์พฤติกรรมการใช้งานที่ทำให้เราเข้าใจผู้ใช้งานระบบของเรามากขึ้น
          </li>
          <li>
            ข้อมูลตัวแทนแบบไม่ระบุผู้ใช้งาน
            ข้อมูลส่วนนี้ไม่ใช่ข้อมูลที่จะสามารถบ่งชี้ถึงตัวบุคคลของผู้เข้าใช้งานได้โดยตรง
            หากแต่เป็นส่วนของข้อมูลที่อำนวยความสะดวกต่อการใช้งานเว็บไซต์และเป็นตัวบ่งชี้พฤติกรรมการใช้งาน
            เราจัดเก็บข้อมูลส่วนนี้ผ่านช่องทางของคุกกี้
          </li>
          <li>
            ข้อมูลตัวแทนแบบระบุผู้ใช้งานทางอ้อม
            ข้อมูลส่วนนี้ไม่ได้จัดเก็บข้อมูลของคุณโดยตรงหากแต่เก็บข้อมูลทางอ้อมที่บ่งชี้ถึงผู้ใช้งานได้ภายหลังเมื่อแลกเปลี่ยนข้อมูลนี้กับเซิฟเวอร์ของเรา
            เช่น User ID เป็นต้น ข้อมูลส่วนนี้เราจัดเก็บผ่านช่องทางของ IndexedDB
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" my={2}>
        ระยะเวลาในการจัดเก็บข้อมูลส่วนบุคคล
      </Typography>
      <Typography component="p">
        ข้อมูลของคุณจะถูกจัดเก็บตราบเท่าที่คุณมีความสัมพันธ์อยู่กับเราหรือตลอดระยะเวลาที่จำเป็นเพื่อให้เป็นไปตามวัตถุประสงค์ของการใช้งานข้อมูลส่วนบุคคลตามนโยบายฉบับนี้
        เราอาจจำเป็นต้องเก็บข้อมูลนานกว่านั้นหากมีข้อกฏหมายกำหนด
        ข้อมูลส่วนบุคคลของคุณจะถูกลบหรือทำลายหรือแปลงให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนได้
        เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาจัดเก็บ
      </Typography>
      <Typography variant="h5" my={2}>
        การประมวลผลข้อมูลส่วนบุคคล
      </Typography>
      <Typography component="p">
        ข้อมูลส่วนบุคคลของคุณจะถูกรวบรวม จัดเก็บ ใช้
        และเผยแพร่เท่าที่จำเป็นเพื่อวัตถุประสงค์การใช้งานต่อไปนี้
        <ul>
          <li>
            เพื่อการสร้างและใช้งานบัญชีผู้ใช้งาน เช่น อีเมล์ ชื่อ-นามสกุล
            เป็นต้น
          </li>
          <li>เพื่อการออกใบรับรองการผ่านหลักสูตร เช่น ชื่อ-นามสกุล เป็นต้น</li>
          <li>
            เพื่อประกอบการชำระค่าสินค้าและบริการของผู้ใช้งาน เช่น
            ข้อมูลธุรกรรมหรือข้อมูลทางบัญชี
          </li>
          <li>
            เพื่อการวิเคราะห์พฤติกรรมผู้บริโภคและปรับปรุงสินค้าและบริการของเรา
            เช่น IP Address ประวัติการใช้งานและประวัติการสั่งซื้อสินค้าและบริการ
            เป็นต้น
          </li>
          <li>
            เพื่อการตลาดและการส่งเสริมการขาย เช่น
            ข้อมูลอันสื่อถึงพฤติกรรมการใช้งาน
          </li>
          <li>เพื่อปฏิบัติตามเงื่อนไขและข้อตกลงการใช้งานของเรา</li>
          <li>เพื่อปฏิบัติตามกฏหมายที่เกี่ยวข้องแห่งราชอาณาจักรไทย</li>
        </ul>
      </Typography>
      <Typography variant="h5" my={2}>
        การเผยแพร่ข้อมูลส่วนบุคคล
      </Typography>
      <Typography component="p">
        เราจะไม่เผยแพร่ข้อมูลส่วนบุคคลแก่บุคคลภายนอกเว้นเสียแต่ได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลเสียก่อน
        หรือให้เป็นไปตามข้อบังคับทางกฏหมายแห่งราชอาณาจักรไทยโดยเราจะเปิดเผยข้อมูลส่วนบุคคลของคุณเท่าที่จำเป็นให้แก่หน่วยงานราชการ
        เช่น ศาล หรือ หน่วยงานราชการ เป็นต้น
        อย่างไรก็ตามการกระทำบางประการจะได้รับการอนุมานว่าคุณได้ให้ความยินยอมในการเปิดเผยข้อมูลของคุณ
        เช่น
        <ul>
          <li>
            เมื่อคุณทำการประเมินผลการเรียนรู้เพื่อรับใบรับรองหลักสูตร
            (Certification)
            เมื่อมีการออกใบรับรองแล้วบุคคลภายนอกอาจเข้าถึงข้อมูลเพื่อยืนยันความถูกต้องของใบรับรองได้
            ข้อมูลที่บุคคลภายนอกอาจได้รับเมื่อตรวจสอบใบรับรองของคุณ เช่น
            ชื่อ-นามสกุล วิชาที่คุณได้สอบใบรับรอง และผลการสอบ เป็นต้น
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" my={2}>
        การติดตามข้อมูลผ่านคุกกี้
      </Typography>
      <Typography component="p">
        คุกกี้คือเทคโนโลยีเว็บที่ช่วยให้เราสามารถติดตามและอำนวยความสะดวกรวมถึงปรับปรุงประสบการณ์การใช้งานเว็บให้ดีขึ้นได้
        ข้อมูลส่วนนี้จะไม่ได้เจาะจงถึงคุณโดยตรง โดยคุกกี้จะถูกแบ่งเป็นสองประเภท
        ดังนี้
        <ul>
          <li>
            คุกกี้ประสิทธิภาพและสถิติ
            คือคุกกี้สำหรับเก็บข้อมูลเพื่อวิเคราะห์ข้อมูลเชิงลึกเกี่ยวกับพฤติกรรมการใช้งานของคุณ
          </li>
          <li>คุกกี้เพื่อการตลาดและการโฆษณา</li>
        </ul>
      </Typography>
      <Typography variant="h5" my={2}>
        การแจกแจงบุคคลผ่าน IndexedDB
      </Typography>
      <Typography component="p">
        IndexedDB คือฐานข้อมูลบนเว็บเบราว์เซอร์ที่สามารถถูกใช้เพื่อเก็บข้อมูลได้
        เราจัดเก็บข้อมูลที่ใช้สื่อถึงคุณทางอ้อม เช่น User ID (UID) ผ่าน
        IndexedDB หากปราศจากข้อมูลส่วนนี้เราจะไม่สามารถบ่งบอกได้ว่าคุณคือผู้ใด
        นั่นทำให้คุณไม่สามารถเข้าถึงสินค้าและบริการที่คุณเคยสั่งซื้อกับเราได้
        เป็นต้น
      </Typography>
      <Typography variant="h5" my={2}>
        การโฆษณาและการตลาด
      </Typography>
      <Typography component="p">
        เราใช้ข้อมูลของคุณเพื่อวิเคราะห์และปรับปรุงสินค้าและบริการ
        รวมถึงการนำเสนอสินค้าและบริการที่คุณน่าจะสนใจ ผ่าน Google และ Facebook
        และบริการอื่น ๆ
        เราอาจส่งข้อมูลสินค้าและบริการที่คุณน่าจะสนใจผ่านช่องทางอื่น เช่น อีเมล์
        เป็นต้น
      </Typography>
      <Typography variant="h5" my={2}>
        สิทธิของเจ้าของข้อมูลส่วนบุคคล
      </Typography>
      <Typography component="p">
        เจ้าของข้อมูลส่วนบุคคลสามารถกระทำการต่อไปนี้โดยร้องขอความช่วยเหลือตรงต่อเรา
        ได้แก่
        <ul>
          <li>
            สิทธิในการถอนความยินยอม
            ภายหลังคุณได้ให้ความยินยอมในการจัดเก็บข้อมูลกับเรา
            คุณสามารถร้องขอเพื่อถอนความยินยอมเมื่อใดก็ได้ตลอดเวลา
          </li>
          <li>สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล</li>
          <li>
            สิทธิขอให้ลบ หรือทำลาย
            หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลได้
          </li>
          <li>
            สิทธิในการขอคัดค้าน
            เจ้าของข้อมูลส่วนบุคคลมีสิทธิคัดค้านการเก็บรวบรวม ใช้
            หรือเปิดเผยข้อมูลเมื่อใดก็ได้
          </li>
          <li>สิทธิในการขอเข้าถึงและขอสำเนาข้อมูลส่วนบุคคล</li>
          <li>
            สิทธิในการขอแก้ไขข้อมูลดังกล่าวให้เป็นปัจจุบันและถูกต้อง
            โดยคุณสามารถแจ้งเราเพื่อดำเนินการหรือคุณสามารถกระทำด้วยตนเองได้ผ่านทางเมนูที่เว็บไซต์เตรียมไว้ให้
          </li>
          <li>
            สิทธิในการขอรับข้อมูลส่วนบุคคลกรณีที่เราได้ทำให้ข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบที่สามารถอ่านหรือใช้งานโดยทั่วไปได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ
          </li>
        </ul>
        หากคุณต้องการความช่วยเหลือเพื่อดำเนินการข้อใดข้อหนึ่งนี้
        โปรดติดต่อเราผ่านช่องทาง
        <ul>
          <li>
            Email:{' '}
            <a href="mailto:codesass@hotmail.com">codesass@hotmail.com</a>
          </li>
          <li>
            Facebook: <a href="https://www.facebook.com/codesass">CODE SASS</a>
          </li>
        </ul>
        เราจะแจ้งผลการดำเนินการภายในระยะเวลา 30 วัน
        นับแต่วันที่เราได้รับคำขอใช้สิทธิจากคุณ
        ตามแบบฟอร์มหรือวิธีการที่เรากำหนด เราอาจคิดค่าใช้จ่ายในการดำเนินการ
        (ถ้าจำเป็น)
        ไม่ว่าการดำเนินการนั้นจะได้รับการแก้ไขหรือถูกปฏิเสธเราจะดำเนินการแจ้งผลลัพธ์ให้ทราบโดยเร็ว
      </Typography>
      <Typography component="p" my={2} fontWeight="bold">
        ประกาศ ณ วันที่ 8 สิงหาคม 2565
      </Typography>
    </Box>
  );
};

export default Privacy;
